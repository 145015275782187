import React from 'react'
import styles from "./Footer.module.css"
import footerLogo from "../../Assets/footerLogo.png"
import location from "../../Assets/location.svg"
import phone from "../../Assets/phone.svg"

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerLogoBox}>
                <img src={footerLogo} alt="" />

            </div>

                <div><h2 style={{ textTransform: "uppercase", color: "white" }}>Join PW Gurukulam School Where Culture Meets Innovation</h2></div>

                <div className={styles.addressContainer}>
                    <div>
                        <img src={location} alt="" />
                        <p>PLOT NO-MI 5, MI 6,MI 7,8 MAUZA GAURI PRAGANA-DHUS, TEH-MUGHALSARAI(PT DDU), DIST-CHANDAULI-232101</p>
                    </div>
                    <div>
                        <img src={phone} alt="" />
                        <p>+91-8951395424</p>
                    </div>
                </div>
                <div style={{ borderTop: "1px solid white", width: "95%" }}>
                </div>
                <div className={styles.copyRightDiv}>
                    <p>© Copyright 2024, PW Gurukulam School. </p>
                </div>
        </div>
    )
}

export default Footer