import React from 'react'
import styles from "./SchoolOverView.module.css"
import acHostel from "../../Assets/acHostel.png"
import acSchool from "../../Assets/acSchool.png"
import neetAndJee from "../../Assets/neetAndJee.png"

const SchoolOverView = () => {

    return (
        <>
            {/* <div className={styles.schoolOverViewContainer} >

                <div className={styles.upperBox1} >
                    <div className={styles.left1}>
                        <p className={styles.largerText}>ALL UNDER ONE ROOF FOR CLASSES</p>
                        <br />
                        <p className={styles.largerText} style={{ color: "#FFC95B" }}>9<span style={{ verticalAlign: "super", fontSize: "1.5vw" }} >TH</span> TO 12<span style={{ verticalAlign: "super", fontSize: "1.5vw" }}>TH</span></p>
                        <br />
                        <p className={styles.smallerText}>In today's fast-paced world, education is no longer just about acquiring knowledge; it's about shaping futures. At PW Gurukulam, this philosophy is not just a motto but a way of life. Every individual's educational journey is meticulously sculpted with personalised guidance, world-class facilities, and in-demand professional career coaching, all within the nurturing embrace of a safe and homely environment at the fully air-conditioned school.</p>

                    </div>
                    <div className={styles.right1}>
                        <img src={acSchool} alt="" />
                        <div className={styles.smallLine}>
                            <div></div>
                        </div>
                        <img src={acHostel} alt="" />
                        <div className={styles.smallLine}>
                            <div></div>

                        </div>
                        <img src={neetAndJee} alt="" />
                    </div>
                </div>

                <div className={styles.bottomBox1}>
                    <div className={styles.eachCont}>
                        <img src={acSchool} alt="" className={styles.acImages} />
                        <h2 className={styles.tagLineImg} >FULLY AIR-CONDITIONED SCHOOL</h2>
                    </div>
                    <div className={styles.eachCont}>
                        <img src={acHostel} alt="" className={styles.acImages} />
                        <h2 className={styles.tagLineImg} >FULLY AIR-CONDITIONED HOSTEL</h2>

                    </div>
                    <div className={styles.eachCont}>
                        <img src={neetAndJee} alt="" className={styles.acImages} />

                        <h2 className={styles.tagLineImg} >FOUNDATION/JEE/NEET/PREPARATION</h2>

                    </div>
                </div>
            </div> */}
            <div className={styles.artFacilities}>
                <p>
                    What Are The State-Of-The-Art- Facilities?
                </p>
            </div>
        </>
    )

}

export default SchoolOverView