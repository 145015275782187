export const createNewRequest = (payload) => {
    return new Promise((resolve, reject) => {


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ ...payload });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://api.zonolith.com/v1/pwSchool/create", requestOptions)
            .then((response) => response.json())
            .then((result) => resolve(result))
            .catch((error) => {
                console.error(error)
                reject(error)
            });


    })

}