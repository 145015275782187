import './App.css';
import LandingPage from './Pages/Landing page/LandingPage';

function App() {
  return (
    <LandingPage/>
  );
}

export default App;
