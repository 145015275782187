import React from "react";
import styles from "./PWHostel.module.css";
import roboticsLab from "./1_page-0001.jpg";
import ProjectNetworkingCafe from "./2_page-0001.jpg";
import spaceRooms from "./3_page-0001.jpg";
import Finance from "./4_page-0001.jpg";


const PWHostel = ({handleScrollToSectionOne=()=>{}}) => {
    return (
        <div className={styles.mainContainerSchool}>
            <div className={styles.topLineBarBox}>
                <h2>
                    PW Gurukulam Hostel
                    {/* <span>Mindful Generations?</span> */}
                </h2>
            </div>
            <div className={styles.topLineDetailedBar}>
                <p>
                    Welcome to PW Gurukulam Hostel, your home away from home. Our hostel
                    is designed to provide a safe, comfortable, and nurturing environment
                    for students. We understand that living away from home can be
                    challenging, and that's why we offer exceptional facilities to make
                    your stay as pleasant and productive as possible. Here are some of the
                    highlights of what you can expect at PW Gurukulam Hostel
                </p>
            </div>

            <div className={styles.imageBoxCont1}>
                <div className={styles.singleBoxCont}>
                    <SingleBox
                        imgLink={roboticsLab}
                        longText="Your safety and well-being are our top priorities. Our hostel is equipped with comprehensive security measures, including 24/7 CCTV surveillance and a secure entry system."
                        smallText="Safe & Nurturing Environment"
                    />
                    <SingleBox
                        smallText="Healthy & Hygienic Food"
                        imgLink={ProjectNetworkingCafe}
                        longText="We believe that good nutrition is essential for a student's health and academic performance. Our on-site kitchen staff prepares a variety of healthy and hygienic meals daily"
                    />
                    <SingleBox
                        smallText="24x7 Hostel Warden Availability"
                        longText="Our dedicated hostel warden is available around the clock to assist with any concerns or issues that may arise. Whether you need help with your accommodation, have a health concern, or simply need someone to talk to, our warden is here to support you 24/7."
                        imgLink={spaceRooms}
                    />
                    <SingleBox
                        longText="All our rooms are fully air-conditioned to provide a pleasant living environment. Our rooms are designed to offer a peaceful and conducive atmosphere for both rest and study, helping you stay focused and refreshed."
                        smallText="Fully Air-Conditioned Rooms"
                        imgLink={Finance}
                    />

                </div>
                <div className={styles.topLineDetailedBar}>
                    <p>
                        At PW Gurukulam Hostel, we are committed to providing a holistic living experience that supports your academic journey. Join us and become a part of our vibrant and supportive community. Experience the perfect blend of comfort, safety, and care at PW Gurukulam Hostel.
                    </p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button className={styles.buttonCon} onClick={handleScrollToSectionOne}>REGISTER NOW</button>
            </div>
        </div>
    );
};

export default PWHostel;

const SingleBox = ({ imgLink = "", smallText = "", longText = "" }) => {
    return (
        <div
            className={styles.singleContainerB}
            style={{ backgroundImage: `url(${imgLink})`,backgroundSize:"cover",backgroundRepeat:"no-repeat" }}
        >
            <div className={styles.innerTextBox}>
                {smallText}

                <div className={styles.descTextCont}>{longText}</div>
            </div>
        </div>
    );
};
