import React from 'react'
import styles from "./Advertisement.module.css"

const Advertisement = () => {
    return (
      

        <div className={styles.advertisement}>
            <p className={styles["scrolling-text"]}>Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat! </p>
            <p className={styles["scrolling-text"]}>Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat! </p>
            <p className={styles["scrolling-text"]}>Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat!       |     Admissions Open - Register Now       |     Admissions Open - Register Now      |      Admissions Open- Secure Your Seat! </p>
        </div>
    )
}

export default Advertisement