import React from 'react'
import styles from "./SalePage.module.css"
import team from "../../Assets/team.png"
import slide1 from "../../Assets/Slider/slider (1).jpg"
import slide2 from "../../Assets/Slider/slider (1).png"
import slide3 from "../../Assets/Slider/slider (2).jpg"
import slide4 from "../../Assets/Slider/slider (2).png"
import slide5 from "../../Assets/Slider/slider (3).png"
import slide6 from "../../Assets/Slider/slider (4).jpg"
import slide7 from "../../Assets/Slider/slider (5).png"
import slide8 from "../../Assets/Slider/slider (6).png"
import slide9 from "../../Assets/Slider/slider (7).png"
import slide10 from "../../Assets/Slider/_DSC3151.jpg"
import slide11 from "../../Assets/Slider/_DSC3167.jpg"
import slide12 from "../../Assets/Slider/_DSC3189.jpg"
import slide13 from "../../Assets/Slider/_DSC3181.jpg"
import slide14 from "../../Assets/Slider/_DSC3194.jpg"

const SalePage = ({handleScrollToSectionOne=()=>{}}) => {

    const images = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10,slide11,slide12,slide13,slide14]; // Add your image URLs here


    return (
        <div >
            {/* <div className={styles.blankDiv}>

            </div> */}

            <div className={styles.salePageContainer}>
                <div className={styles.leftSaleBox} >
                    <p className={styles.smallerText1}>Let’s get together and make</p>
                    <p className={styles.smallerText2} style={{ color: "#FFC95B" }}>Your dream true</p>

                    <button className={styles.buttonCon} onClick={handleScrollToSectionOne}>Book A Call With Our Counsellor</button>
                </div>
                <div className={styles.rightTeam}>
                    <img src={team} alt="Team" />
                </div>
            </div>
            <div style={{ padding: "2vw", background: "white" }}>

                <div style={{ padding: "2vw" }}>
                    <h1 style={{ color: "#252525", textAlign: "center", fontWeight: "700", textTransform: "uppercase" }}>Glimpses of pw gurukulam</h1>
                </div>



                <div className={styles.album}>
                    {images.map((image, index) => (
                        <div
                            key={index}
                        >
                            <img src={image} alt="" />
                        </div>
                    ))}
                </div>


                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className={styles.buttonCon}onClick={handleScrollToSectionOne} >SCHEDULE A SCHOOL VISIT</button>
                </div>

            </div>
        </div>
    )
}

export default SalePage