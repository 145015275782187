import React, { useEffect, useRef, useState } from 'react'
import styles from "./DetailsWithImages.module.css"
import classRooms from "../../Assets/classRooms.png"
import Perspectives from "../../Assets/Perspectives.png"
import Labs from "../../Assets/Labs.png"
import Areas from "../../Assets/Areas.png"
import Tracking from "../../Assets/Tracking.png"
import Tech from "../../Assets/Tech.png"
import Integration from "../../Assets/Integration.png"

const DetailsWithImages = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        function handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
            const scrolled = scrollTop + windowHeight;
            const scrollPercentage = (scrolled / documentHeight) * 100;
            setScrollPercentage(calculatePercentage(scrollPercentage));
        }

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Detach event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <div className={styles.mainBoxOfDetailsPage}>
            <div className={styles.eachContainerBox}>
                <div className={styles.imgContainer}>
                    <img src={classRooms} alt="ClassRooms" />
                </div>
                <div className={styles.boxText}>
                    <h2>Tech-Integrated Classrooms</h2>
                    <br />
                    <p>Students can improve their learning experience when technology is skillfully incorporated into the curriculum to improve education. It cultivates digital competence and critical thinking abilities in a dynamic learning environment.</p>
                </div>
            </div>

            <div className={styles.eachContainerBox}>
                <div className={styles.boxText}>
                    <h2>Real-World Perspectives</h2>
                    <br />
                    <p>Focuses on teaching students with the help of real-life scenarios & this approach aims to make learning more relevant & engaging for students.</p>
                </div>
                <div className={styles.imgContainer}>
                    <img src={Perspectives} alt="ClassRooms" />
                </div>
            </div>

            <div className={styles.eachContainerBox}>
                <div className={styles.imgContainer}>
                    <img src={Labs} alt="ClassRooms" />
                </div>
                <div className={styles.boxText}>
                    <h2>Well-Equipped Labs</h2>
                    <br />
                    <p>Modern and high-quality equipment is available in fully-furnished labs which are necessary for performing examinations and research seamlessly.</p>
                </div>
            </div>

            <div className={styles.eachContainerBox}>
                <div className={styles.boxText}>
                    <h2>Expansive Recreational Areas</h2>
                    <br />
                    <p>Large recreational spaces for promoting the overall well-being of students including their mental, social, and physical health. They can play, relax, and learn how to interact better.</p>
                </div>
                <div className={styles.imgContainer}>
                    <img src={Areas} alt="ClassRooms" />
                </div>
            </div>
            <br />
            <br />
            <div className={styles.eachContainerBox}>
                <div className={styles.imgContainer}>
                    <img src={Tracking} alt="ClassRooms" />
                </div>
                <div className={styles.boxText}>
                    <h2>AC Buses with GPS Tracking</h2>
                    <br />
                    <p>Our buses are equipped with advanced GPS tracking for real-time monitoring, ensuring enhanced safety and reliability.</p>
                </div>
            </div>

            <div className={styles.eachContainerBox}>
                <div className={styles.boxText}>
                    <h2>Futuristic Tech</h2>
                    <br />
                    <p>The school focuses on” Futuristic Tech” which implies an approach to embracing creativity, innovation, and actively contributing to solving complex challenges.</p>
                </div>
                <div className={styles.imgContainer}>
                    <img src={Tech} alt="ClassRooms" />
                </div>
            </div>

            <div className={styles.eachContainerBox}>
                <div className={styles.imgContainer}>
                    <img src={Integration} alt="ClassRooms" />
                </div>
                <div className={styles.boxText}>
                    <h2>Steam Integration</h2>
                    <br />
                    <p>Science, Technology, Engineering, Arts, and Mathematics, or STEAM, integration is a multidisciplinary approach that focuses on all these areas. It is intended to help students develop their critical thinking, creativity, problem-solving, and teamwork abilities.</p>
                </div>
            </div>
            <div className={styles.arrowDiv} >
                <div >
                    <div className={styles.innerDivOfArrow} style={{ height: `${scrollPercentage}%` }}>
                        <div className={styles.arrowSign}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsWithImages
// function calculatePercentage(value) {
//     value = Math.min(Math.max(value, 45), 78);

//     const percentage = ((value - 45) / (78 - 45)) * 100;

//     return percentage.toFixed();
// }


function calculatePercentage(value) {
  
   
    value = Math.min(Math.max(value, 40), 65);

    const percentage = ((value - 40) / (65 - 40)) * 100;

    let roundedPercentage;
    if (percentage >= 0 && percentage <= 10) {
        roundedPercentage = 5;
    } else if (percentage > 10 && percentage <= 30) {
        roundedPercentage = 10;
    } else if (percentage > 30 && percentage <= 50) {
        roundedPercentage = 20;
    } else if (percentage > 50 && percentage <= 70) {
        roundedPercentage = 40;
    } else if (percentage > 70 && percentage <= 90) {
        roundedPercentage = 60;
    } else if (percentage > 90 && percentage <= 100) {
        roundedPercentage = 80;
    }

    return roundedPercentage;
}
