import React from 'react'
import styles from "./WhoWeAre.module.css"
import studentPic from "../../Assets/students.png"
import singleTeacher from "../../Assets/singleTeacher.png"

const WhoWeAre = () => {
    return (
        <div className={styles.whoWeAreContainer}>
            <div className={styles.leftWhoCont}>
                <div className={styles.topleftTextBox}>
                    <p >Who <span>  We Are?</span> </p>
                </div>
                <br />
                <div className={styles.belowSmallText}>
                    Physics Wallah is a leading educational platform that has served several test categories, including <b>JEE, NEET, UPSC, Defence</b> and more. In the offline mode, PW has catered to and operates more than 100 offline centers across India.
                </div>
                <br />
                <div className={styles.belowSmallText}>
                    Now, PW is here with the top CBSE school i.e. PW Gurukulam School- Where Culture Meets Innovation to bridge the gap between traditional learning and contemporary innovation.
                </div>
                <br />
                <div className={styles.belowSmallText}>
                    PW Gurukulam School strives to build cultural values, nurturing heritage traditions while embracing the elements of innovation.
                </div>
                <br />
            </div>
            <div className={styles.rightWhoCont}>

                <div className={styles.studentBox}>
                    <img src={studentPic} alt="" />
                    <div>
                        <h4 style={{ color: "#FFC95B" }}>100+</h4>
                        <p style={{ color: "#767676" }}>OFFLINE CENTERS</p>
                    </div>
                </div>
                <div className={styles.studentBox} >
                    <div>
                        <h4 style={{ color: "#FFC95B" }}>4 Cr+</h4>
                        <p style={{ color: "#767676" }}>STUDENTS</p>
                    </div>
                    <img src={singleTeacher} alt="" />

                </div>

            </div>
        </div>
    )
}

export default WhoWeAre