import styles from "./inputField.module.css";
import React from 'react'

const InputField = (params) => {
  const { onChange = () => { }, placeholder = "Type here", required = false, type = "", value = "" } = params
  return (
    <div className={styles.inputContainer}>
      <input
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value}
      />
    </div>
  )
}

export default InputField
