import React, { forwardRef, useState } from 'react'
import styles from "./EnquiryPage.module.css"
import indianFlag from "../../Assets/indianFlag.png"
import InputField from '../InputText/InputField'
import { createNewRequest } from './actions'

const EnquiryPage = forwardRef((props,ref) => {



    const [formData, setFormData] = useState({
        studentName: "",
        studentEmail: "",
        studentPhoneNumber: "",
        guardianName: "",
        interestedGrade: "",

    })





    const handleSubmit = async e => {

        e.preventDefault()

        if (formData.studentPhoneNumber.toString().length !== 10) {
            return alert("Enter  Valid mobile number")
        }

        const resp = await createNewRequest({
            email: formData.studentEmail,
            grade: formData.interestedGrade,
            guardianName: formData.guardianName,
            mobileNumber: formData.studentPhoneNumber,
            studentName: formData.studentName,
            center: "Varanasi"
        })
        if (resp.status == 201) {
            alert("Your request has been submitted")
            setFormData({
                studentName: "",
                studentEmail: "",
                studentPhoneNumber: "",
                guardianName: "",
                interestedGrade: "",
            })
        } else {
            alert("Something went Wrong")
        }

    }


    return (
        <div className={styles.mainContainer} ref={ref}>
            <div className={styles.contentContainer}>
                <div className={styles.leftContentBox}>
                    <div className={styles.brandDesc}>Where We Build Character, Values & Foster <span> Academic Growth </span> </div>
                    <div style={{ height: "10px" }}></div>
                    <button className={styles.admissionButton}>VARANASI ADMISSION OPEN FOR SESSION 2024-25</button>
                    <div style={{ height: "10px" }}></div>
                    <div className={styles.classText}>Pre-Nursery to Grade IX and XI</div>
                </div>
                <div className={styles.rightContentBox} >
                    <div className={styles.enquiryText}>ENQUIRE NOW</div>
                    <br />
                    <form onSubmit={handleSubmit} >
                        <InputField
                            onChange={e => setFormData({ ...formData, studentName: e.target.value })}
                            placeholder='Enter Student Name*'
                            required={true}
                            type='text'
                            value={formData.studentName}
                        />
                        <InputField
                            onChange={e => setFormData({ ...formData, studentEmail: e.target.value })}
                            placeholder='Enter Email Address'
                            required={true}
                            type='email'
                            value={formData.studentEmail}
                        />
                        <InputField
                            onChange={e => setFormData({ ...formData, studentPhoneNumber: e.target.value })}
                            placeholder='Enter Your Mobile number*'
                            required={true}
                            type='number'
                            value={formData.studentPhoneNumber}
                        />
                        {/* <MobileAndOtp
                            onChange={e => setFormData({ ...formData, studentPhoneNumber: e.target.value })}
                            placeholder='Enter Your Mobile number*'
                            required={true}
                            type='number'
                            value={formData.studentPhoneNumber} /> */}

                        <InputField
                            onChange={e => setFormData({ ...formData, guardianName: e.target.value })}
                            placeholder='Enter Guardian Name*'
                            required={true}
                            type='text'
                            value={formData.guardianName}
                        />

                        <DropDown
                            onChange={e => setFormData({ ...formData, interestedGrade: e.target.value })}
                            placeholder='Select Interested Grade*'
                            required={true}
                            value={formData.interestedGrade} />
                        <button className={styles.submitButtonEnquiry} type='submit'>Submit Now</button>
                    </form>
                </div>

            </div>
        </div>
    )
})

export default EnquiryPage


const DropDown = ({ onChange = () => { }, placeholder = "", value, required = false }) => {
    return <div className={styles.dropdownForGrade}>
        <select value={value} onChange={onChange} required={required}>
            <option value="" defaultValue disabled>{placeholder}</option>
            <option value="preNursery">Pre Nursery</option>
            <option value="nursery">Nursery</option>
            <option value="lkg">LKG</option>
            <option value="ukg">UKG</option>
            <option value="grade1">Grade 1</option>
            <option value="grade2">Grade 2</option>
            <option value="grade3">Grade 3</option>
            <option value="grade4">Grade 4</option>
            <option value="grade5">Grade 5</option>
            <option value="grade6">Grade 6</option>
            <option value="grade7">Grade 7</option>
            <option value="grade8">Grade 8</option>
            <option value="grade9">Grade 9</option>
            <option value="grade10">Grade 10</option>
            <option value="grade11">Grade 11</option>
            <option value="grade12">Grade 12</option>
        </select>
    </div>
}

const MobileAndOtp = (params) => {
    const { onChange = () => { }, placeholder = "Type here", required = false, type = "", value = "" } = params
    return <div className={styles.otpContainer}>
        <div className={styles.indianFlagCont}>
            <img src={indianFlag} alt="" />
            <div>+91- </div>
        </div>
        <div>
            <div className={styles.inputContainer1}>
                <input
                    type={type}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    value={value}
                />
            </div>
        </div>
        <div className={styles.sendOtpBtn}><button type='button'>Send OTP</button></div>
    </div>
}