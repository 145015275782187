import React from 'react'
import styles from "./TopNavbar.module.css"
import brandLogo from "../../Assets/brandLogo.png"
// import telephone from "../../Assets/Group 5.png"
import telephone from "../../Assets/telephone 1.png"
import trustDeed from "./GURUKULAM EDUCATION TRUST Deed.pdf"
import buildingCertificate from "./Building Certificate.pdf"
import healthCertificate from "./Health and Sanitary Certificate.jpeg"
import acamedicCalender from "./ACADEMIC PLANNER.pdf"
import parentTeacherAssociation from "./Parent Teacher Association.jpeg"
import feesStructure from "./Fees.pdf"
import schoolManagementCommittee from "./School management committee.jpeg"
import inProcess from "./Mandatory Disclosure Details _ SARAS 5.0.pdf"



const TopNavbar = () => {
  return (
    <>
      <div className={styles.mainContainer} >
        <div className={styles.boxContainer} >
          <div className={styles.leftContainer}>
            <img src={brandLogo} alt="Pw" />
          </div>


          <div className={styles.rightContainer}>
            <div className={styles.phoneBox}>
              <img src={telephone} alt="Tel" />
            </div>
            <div className={styles.phoneBox1}>
              +91-8737860065, 8737860067
            </div>
          </div>
          <div>
            <div className={styles.dropDownCenter} >
              <Dropdown />
            </div>
          </div>
        </div>

      </div>
      <div className={styles.bottomDrop} >
        <Dropdown />
      </div>
    </>
  )
}

export default TopNavbar

const Dropdown = () => {
  const handleOptionClick = (url) => {
    if (!url) {
      return;
    }
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.Dropdown1}>
      <select onChange={(e) => handleOptionClick(e.target.value)}>
        <option value="">MANDATORY DISCLOSURE</option>
        <option value={trustDeed}>Trust Deed</option>
        <option value={inProcess}>RTE(School Registration)</option>
        <option value={buildingCertificate}>Building Certificate</option>
        <option value={inProcess}>No Objection Certificate</option>
        <option value={healthCertificate}>Health & Sanitary Certificate</option>
        <option value={inProcess}>Fire & Safety Certificate</option>
        <option value={acamedicCalender}>Academic Calender</option>
        <option value={parentTeacherAssociation}>Parent-Teacher Association</option>
        <option value={schoolManagementCommittee}>School Management Committee</option>
        <option value={feesStructure}>Fees Structure</option>
        <option value={inProcess}>SARAS</option>
      </select>
    </div>
  );
};