import React from 'react'
import styles from "./SchoolDetailed.module.css"
import roboticsLab from "../../Assets/roboticsLab.png"
import ProjectNetworkingCafe from "../../Assets/ProjectNetworkingCafe.png"
import spaceRooms from "../../Assets/SpaceRooms.png"
import Finance from "../../Assets/Finance.png"
import pedagogy from "../../Assets/pedagogy.png"
import teaching from "../../Assets/teaching.png"

const SchoolDetailed = ({handleScrollToSectionOne=()=>{}}) => {
    return (
        <div className={styles.mainContainerSchool}>

            <div className={styles.topLineBarBox}>
                <h2>
                    How PW Gurukulam School Is Building <span>Mindful Generations?</span>
                </h2>
            </div>
            <div className={styles.topLineDetailedBar}>
                <p>
                    PW Gurukulam is now here among the best schools in Varanasi, which speaks volumes about its dedication to fostering in addition to academic excellence the timeless qualities of compassion, integrity, and resilience.
                </p>
            </div>
            <div className={styles.imageBoxCont1} >
                <div className={styles.singleBoxCont}>
                    <SingleBox imgLink={roboticsLab} longText='Drives innovation and pushes the boundaries of possibilities.' smallText='AERO ROBOTICS LAB' />
                    <SingleBox smallText='PROJECT NETWORKING CAFE' imgLink={ProjectNetworkingCafe} longText='It plays a valuable role in fostering collaboration and community  building through an innovative atmosphere' />
                    <SingleBox smallText='SPACE ROOMS' longText='Here students can learn about gravity and discover more about the solar systems.' imgLink={spaceRooms} />
                    <SingleBox
                        longText='Learn how to set realistic financial goals and create an actionable plan to achieve them.'
                        smallText='FINANCIAL LITERACY COURSES'
                        imgLink={Finance}
                    />
                    <SingleBox
                        longText='Nurturing independent thinkers through hands-on learning and self exploration'
                        smallText='MONTESSORI PEDAGOGY'
                        imgLink={pedagogy}
                    />
                    <SingleBox
                        longText='Now students can learn with innovative teaching methods to develop their knowledge and personality.'
                        smallText='INNOVATIVE TEACHING APPROACH'
                        imgLink={teaching}
                    />
                </div>

            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button className={styles.buttonCon} onClick={handleScrollToSectionOne} >REGISTER NOW</button>
            </div>
        </div>
    )
}

export default SchoolDetailed

const SingleBox = ({ imgLink = "", smallText = "", longText = "" }) => {
    return <div className={styles.singleContainerB} style={{ backgroundImage: `url(${imgLink})` }}>
        <div className={styles.innerTextBox}>
            {smallText}

            <div className={styles.descTextCont}>
                {longText}
            </div>
        </div>

    </div>
}